#news {
  height: fit-content;
  margin-top: 5vh;
  padding-bottom: 5vh;
}

#newsContent {
  padding-top: 5%;
}

.news_card_entry {
  margin: 16px 0;
}

@media only screen and (max-width: 1024px) {
  #news {
    background: #f5f5f5;
  }
}
