#footer {
  height: 10vh;
  background-color: #888888;
}

#footerContent {
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem 0;
}

#footerSocialIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


@media only screen and (max-width: 1024px) {
  #footer {
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #455A64  ;
  }

  #footerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 2em 0;
  }

  #footerSocialIcons {
    margin-top: 2em;
  }

  #footer_copyright {
    margin-top: 1em;
    font-size: 1em;
    color: #dcdcdc;
  }

  .MuiIconButton-root-124 {
    color: #dcdcdc !important;
  }
}
