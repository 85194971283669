#partners {
  min-height: fit-content;
  margin-top: 10vh;
  margin-bottom: 5vh;
}

#partners_content {
  padding-top: 5%;
}

#partner-collage {
  /* background-image: url("../../data/images/partners/partner_collage.png");
  background-repeat: no-repeat;
  background-size: cover; */
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
}

#partnerImgBox {
  width: 90%;
  margin: 0 auto;
}

#partnersImg {
  width: 100%;
}
