a {
  text-decoration: none;
}

.project_cardBody {
  width: 30%;
  height: 600px;
  background-color: #fff;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
}

.project_card_contentBox {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  height: fit-content;
  max-height: inherit;
  justify-content: space-between;
  text-align: center;
}

.project_title {
  font-size: 2rem !important;
  margin-bottom: 1rem !important;
  color: #c00000;
  font-weight: 600 !important;
  padding: 0 !important;
  text-decoration: none;
}

.project_imgBox {
  width: 100%;
  margin-bottom: 1rem;
}

.project_img {
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.project_text_box {
  margin-bottom: 2vh;
  text-align: left;
  padding: 0 0.5rem;
}

.project_text {
  text-align: left;
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #424242;
  font-weight: 300;
  padding: 0 0.5em;
}

@media only screen and (max-width: 1600px) {
  .project_cardBody {
    width: 45%;
    height: auto;
    background-color: #fff;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1024px) {
  .project_cardBody {
    width: 90%;
    height: auto;
    background-color: #fff;
    margin: 0 auto;
  }

  .project_card_contentBox {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    width: 100%;
    height: auto;
    justify-content: space-between;
    text-align: center;
  }

  .project_textContentBox {
    width: 95%;
    padding-bottom: 3rem;
  }

  .project_title {
    font-size: 1.5em !important;
    font-weight: 600 !important;
    margin: auto !important;
    padding: 8px !important;
  }

  .project_text {
    text-align: left;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #424242;
    font-weight: 300;
  }
}
