#projects {
  min-height: fit-content;
  margin-bottom: 5vh;
}

#projects_content {
  padding-top: 5%;
}

#projects_card_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 0 5em;
}

.project_card_entry {
  margin: 16px 0px;
}

@media screen and (max-width: 1000px) {
  #projects_card_box {
    margin: 0em;
  }
}

@media screen and (max-width: 1024px) {
  #projects_content {
    background: #f5f5f5;
  }
}
