#about {
  height: fit-content;
  margin-top: 10vh;
  margin-bottom: 25vh;
}

#aboutContent {
  padding-top: 20%;
}

#aboutText {
  font-size: 3vw;
  padding: 0 2rem;
  text-indent: 70px;
}

@media only screen and (max-width: 1024px) {
  #about {
    min-height: 30vh;
  }

  #aboutContent {
    padding-top: 2rem;
  }

  #aboutText {
    font-size: 1rem;
    text-indent: 0;
    color: #424242;
  }
}

@media screen and (min-width: 1300px) {
  #aboutText {
    font-size: 2vw;
  }
}
