#flyer_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
    width: 50%;
}

#flyer_container > img {
    width: 100%;
    object-fit: fill;
}