#navBody {
  background-color: #ffffff;
  max-height: 9vh;
}

#logoContainer {
  max-height: 9vh;
}

#navTitle {
  font-size: 2vw;
  color: #3a3333;
  font-weight: bold;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 70%;
}

#navButtonBox {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.navButton {
  color: #c00000 !important;
  font-weight: 600 !important;
  margin: 0 0.5em !important;
  font-size: 1.5vw !important;
}

#logoImg {
  margin-top: 2px;
  margin-left: 1rem;
  max-height: 64px;
  width: auto;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .navContent {
    background: #f5f5f5;
    display: flex;
    flex-direction: row;
  }

  .hamMenu {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    /** probably shouldn't use px **/
    margin-left: 8px;
    width: 1.5em;
    height: 1.5em;
  }
}
