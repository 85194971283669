#officers {
  height: fit-content;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

#officerContent {
  padding-top: 5%;
}

#officer_card_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 0 5em;
}

@media only screen and (max-width: 1024px) {
  #officers {
    height: fit-content;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  #officerContent {
    padding-top: 2em;
  }

  #officer_card_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
  }
}
