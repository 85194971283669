.officer_cardBody {
  width: 28%;
  height: 400px;
  background-color: #fff;
  margin: auto;
  margin-bottom: 2rem;
}

.officer_card_contentBox {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 400px;
  justify-content: space-between;
  text-align: center;
}

.officer_headshotBox {
  width: 300px;
  margin: 0 auto;
}

.officer_headshotImg {
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
  border-radius: 50%;
}

.officer_textContentBox {
  width: 100%;
}

.officer_name {
  color: #c00000;
}

@media only screen and (max-width: 1024px) {
  .officer_cardBody {
    width: 20rem;
    height: 20rem;
    background-color: #fff;
    margin: auto;
    margin-bottom: 8rem;
  }

  .officer_card_contentBox {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    text-align: center;
  }

  .officer_headshotBox {
    margin: 0 auto;
  }

  .officer_headshotImg {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
  }

  .officer_textContentBox {
    width: 100%;
  }
}
