a {
  text-decoration: none;
}

.cardBody {
  width: 95%;
  height: 200px;
  background-color: #fff;
  margin: auto;
  margin-bottom: 1.25rem;
}

.contentBox {
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 100%;
  justify-content: space-between;
}

.imgBox {
  width: 400px;
}

.contentImg {
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.textContentBox {
  width: 70vw;
  padding-right: 1em;
  padding-left: 0.1em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardTitle {
  font-size: 2vw !important;
  margin-bottom: 1vh !important;
  color: #c00000;
}

@media only screen and (max-width: 1024px) {
  a {
    text-decoration: none;
  }

  #cardBody {
    width: 95%;
    height: 100%;
    background-color: #fff;
    margin: auto;
    margin-bottom: 1.25rem;
  }

  #contentBox {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    height: auto;
    justify-content: space-between;
  }

  #imgBox {
    width: 100%;
    height: 15rem;
  }

  #contentImg {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 15rem;
  }

  #textContentBox {
    width: 90%;
    padding-right: 1em;
    padding-left: 1em;
    overflow: hidden;
    text-align: center;
    padding-bottom: 1em;
  }

  .cardTitle {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1em;
    color: #cc0000;
    font-weight: 500;
  }

  #contentSummary {
    text-align: left;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #424242;
    font-weight: 300;
  }
}
