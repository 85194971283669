#contact {
  height: fit-content;
  min-height: 300px;
  margin-top: 10vh;
  margin-bottom: 8vh;
}

.contact_text {
  font-size: 2rem !important;
  text-align: center;
}
