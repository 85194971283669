#awards {
  height: fit-content;
  margin-top: 5vh;
  padding-bottom: 5vh;
}

#awards_content {
  padding-top: 5%;
}

@media only screen and (max-width: 1024px) {
  #awards {
    background: #f5f5f5;
  }
}
