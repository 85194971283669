#coverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: fit-content;
  margin-top: 20vh;
  margin-bottom: 5vh;
}

#coverImg {
  max-width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  #coverContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: auto;
    margin-top: 4rem;
  }
}